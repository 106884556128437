export default [
    // {
    //     path: "/api-key",
    //     name: "ApiKey",
    //     component: () => import("@/views/apiKey"),
    //     meta: {title: "Api Key", activeMenu: "/api-key"},
    // },
    {
        path: "/model-overview",
        name: "ModelOverview",
        component: () => import("@/views/modelOverview"),
        meta: {title: "模型广场", activeMenu: "/model-overview"},
    },
    {
        path: "/model-overview/detail/:id",
        name: "ModelOverviewDetail",
        component: () => import("@/views/modelOverview/detail"),
        meta: {title: "模型详细", activeMenu: "/model-overview"},
    },
]
