<template>
  <div :class="classObj" class="app-wrapper">
    <Header></Header>

    <div class="app-container">
      <Sidebar></Sidebar>
      <div class="main-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import {mapState} from "vuex";

export default {
  name: "Layout",
  // eslint-disable-next-line vue/no-unused-components
  components: {Header, Sidebar},
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.app-wrapper {
  position: relative;
  .app-container {
    .main-container {
      transition: margin-left 0.28s;
      margin-left: $sidebar-width;
      overflow: hidden;
      position: relative;
      height: calc(100vh - #{$header-height});
    }
  }
}
</style>
