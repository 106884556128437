<template>
  <div class="page-wrap">
    <div class="page-header">
      <div class="page-title" v-if="$slots.pageHeaderTitle">
        <slot name="pageHeaderTitle">{{ title }}</slot>
      </div>
      <div class="page-title" v-else>
        <slot name="pageHeaderTitle">{{ title }}</slot>
      </div>
      <yv-divider direction="vertical" v-if="$slots.pageHeaderTab"></yv-divider>
      <div class="page-header__tabs" v-if="$slots.pageHeaderTab">
        <slot name="pageHeaderTab"></slot>
      </div>
      <div class="page-header__tips">
        <slot name="pageHeaderTips"></slot>
      </div>
    </div>
    <div class="page-main">
      <slot name="pageMain"></slot>
      <yv-scrollbar v-if="!$slots.pageMain">
        <slot name="page-alert"></slot>
        <WhiteBox>
          <div class="page-main__toolbar" v-if="$slots.pageMainToolbar">
            <slot name="pageMainToolbar"></slot>
          </div>
          <div class="page-main__content">
            <div class="content-wrap">
              <div class="toolbar__list" v-if="$slots['table-tools']">
                <slot name="table-tools"></slot>
              </div>
              <slot></slot>
              <div class="table-pager" v-if="$slots['table-pager']">
                <slot name="table-pager"></slot>
              </div>
            </div>
          </div>
        </WhiteBox>
        <div class="padding-bottom__space"></div>
      </yv-scrollbar>
    </div>
  </div>
</template>

<script>
import WhiteBox from "./WhiteBox";
export default {
  name: "page",
  components: {
    WhiteBox,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.page-wrap {
  .page-header {
    position: relative;
    display: flex;
    align-items: center;
    height: $header-height;
    padding: 0 16px;
    background-color: #ffffff;
    // box-shadow: $white-box-shadow;
    border-bottom:$border-base;
    min-height: 51px;
    .page-title {
      font-size: 16px;
      color: $text-primary;
      font-weight: 600;
      margin-right: 16px;
    }
    .page-header__tabs {
      margin-left: 24px;
      ::v-deep .yv-tabs__header {
        height: $header-height !important;
        margin: 0;
        .yv-tabs__nav-wrap {
          .yv-tabs__active-bar {
            height: 3px;
          }
          .yv-tabs__item {
            height: $header-height;
            line-height: $header-height;
          }
          &::after {
            display: none;
          }
        }
      }
    }
    .page-header__tips {
      font-size: 14px;
      position: absolute;
      right: 16px;
    }
  }

  .page-main {
    .white-box {
       //min-height: calc(100vh - #{$header-height * 2} - 32px);
    }

    ::v-deep .yv-scrollbar__wrap {
      padding: 16px 16px 0 16px;
      box-sizing: border-box;
      height: calc(100vh - #{$header-height * 2});
      overflow-x: hidden;
      .padding-bottom__space{
        padding-bottom: 16px;
      }
    }
    .page-main__toolbar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-color-light;
      padding-bottom: 12px;
      .search-input {
        width: 210px;
      }
      & + .page-main__content {
        margin-top: 12px;
      }
    }
    .page-main__content {
      .content-wrap {
        .table-actions {
          padding-bottom: 8px;
        }

        .cell-text-wrap {
          line-height: 16px;
        }
        .cell-icon-wrap {
          .text-wrap {
            margin-left: 4px;
          }
        }

        .cell-logo-wrap {
          display: flex;
          align-items: center;
          .logo-wrap {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            border-radius: 6px;
            background: #f5f7fa;
            img {
              height: 22px;
              width: auto;
            }
          }
          .text-wrap {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
