export default [
    {
        path: "/ssh",
        name: "Ssh",
        component: () => import("@/views/ssh/ssh-list"),
        meta: {title: "SSH密钥", activeMenu: "/ssh"},
        // access:'mainAccount',
    },
    {
        path: "/ssh/detail/:id",
        name: "SshDetail",
        component: () => import("@/views/ssh/ssh-detail"),
        meta: {title: "SSH密钥详细", activeMenu: "/ssh"},
        // access:'mainAccount',
    },
]
