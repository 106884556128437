import Axios from 'axios'
import Router from '@/router'
import EnvironmentConfig from './environment'
import store from "../store";
import AuthService from "./auth"

const axios = Axios.create({
  baseURL: process.env.BASE_URL + 'controller/ui/',
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  validateStatus: status => (status >= 200 && status < 300),
  headers: {
    'X-REQUESTED-WITH': 'XMLHttpRequest'
  }
})

axios.interceptors.request.use(config => {

  return config
}, err => {
  return Promise.resolve(err)
})

axios.interceptors.response.use(response => {
  if (response.headers['content-type'].includes('openxmlformats')) {
    download(response)
    return { success: true }
  }
  if (response.headers['content-type'].includes('json') && response.config.responseType === 'blob') {
    // 当下载接口报错时候处理，这种情况返回的一定是错误信息，return Promise.reject
    return new Response(response.data).text().then(res => {
      return Promise.reject(JSON.parse(res))
    })
  } else {
    if (!response.data.success) {
      errorHandle(response.data.errorCode)
      return Promise.reject(response.data)
    }
  }
  return response.data
}, error => {
  const response = error.response
  errorHandle(response.status)
  return Promise.reject(response.message || '未知错误')
})

export const errorHandle = function (code) {
  if (code === 601) {
    AuthService.getLogout().then(() => {
      store.dispatch("user/logout")
      const cb = window.location.href
      window.location.href = `${EnvironmentConfig.passport.api_root}/auth/login?callback=${encodeURIComponent(cb)}`
    })
  } else if (code === 610 || code === 611) {
    const cb = window.location.href
    window.location.href = `${EnvironmentConfig.cloud.ui_root}?callback=${encodeURIComponent(cb)}`
  } else if (code === 621) {
    Router.replace({ name: 'error-621' })
  }
}
export const download = function(response) {
  const blob = new Blob([response.data],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
  const fileName = response.headers['content-disposition'].split('"')[1]
  const link = document.createElement('a')
  link.download = decodeURI(fileName)
  link.href = window.URL.createObjectURL(blob)
  link.click()
  window.URL.revokeObjectURL(link.href)
}
export default axios
