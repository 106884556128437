import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout.vue";
import AuthService from "../api/auth";
import RouterWorkbench from "./module/router-workbench";
import RouterSSH from "./module/router-ssh";
import RouterInstance from "./module/router-instance";
import RouterResource from "./module/router-resource";
import RouterNetdisk from "./module/router-netdisk";
import RouterModel from "./module/router-model";
import RouterAsset from "./module/router-asset";
import RouterUser from "./module/router-user";
import RouterImage from "./module/router-image";
import RouterLlm from "./module/router-llm";
import store from "../store";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "*",
    name: "Layout",
    component: Layout,
    redirect: "/workbench",
    children: [
      ...RouterWorkbench,
      ...RouterSSH,
      ...RouterInstance,
      ...RouterNetdisk,
      ...RouterModel,
      ...RouterAsset,
      ...RouterUser,
      ...RouterResource,
      ...RouterImage,
      ...RouterLlm,
    ],
  },
  {
    path: "/610",
    name: "error-610",
    component: () => import("@/views/error/error-610"),
  },
  {
    path: "/611",
    name: "error-611",
    component: () => import("@/views/error/error-611"),
  },
  {
    path: "/621",
    name: "error-621",
    component: () => import("@/views/error/error-621"),
  },
  {
    path: "/owed",
    name: "error-owed",
    component: () => import("@/views/error/error-owed"),
  },
  {
    path: "/coming",
    name: "coming-soon",
    component: () => import("@/views/error/coming-soon"),
  },
  {
    path: "/invite",
    name: "Invite",
    component: () => import("@/views/workbench/invite")
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.activeMenu || to.meta.activeMenu !== from.meta.activeMenu){
    // 跳转的不是子页面， 清空主页面的缓存
    window.localStorage.removeItem('_table_data_searchForm_')
    window.localStorage.removeItem('_table_data_sort_')
    window.localStorage.removeItem('_table_data_page_')
    store.dispatch("app/setActiveTab", undefined)
  }
  if (to.name.startsWith("error-")) {
    next()
  } else {
    if (store.getters.user && store.getters.user.accountId) {
      filterRouter(to, store.getters.user)
      next()
    } else {
      AuthService.getUserInfo().then(user => {
        store.dispatch('user/setUser', user)
        store.dispatch('user/setRegion', user.regions)
        filterRouter(to, user)
        next()
      })
    }
  }
})

const filterRouter = function filterRouter(to, user) {
    if (routes && user && to && to.name !== 'Workbench') {
        routes[0].children.forEach((item) => {
            if (item.path === to.path) {
                if (item.access === 'mainAccount' && !user.mainAccount) {
                    router.push({name: "Workbench"})
                }
                return
            }
        })
    }
}

export default router;
