import "es6-promise/auto"
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./icons";
import YovoleUI from "@yovole/yovole-ui";
import "./assets/style/yovole-ui/index.css";
import "./assets/style/variables.scss";
import "./assets/style/common.scss";
import "./assets/style/initial.scss";
import "./assets/style/projects.scss";
import Page from "./components/layout/Page";
import PageDetail from "./components/layout/PageDetail";
import WhiteBox from "./components/layout/WhiteBox";
import PermissionPage from "./components/layout/PermissionPage";
import Utils from '@/util/utils'
import VueClipboard from "vue-clipboard2";
Vue.use(YovoleUI, {size: "small"});
Vue.use(VueClipboard)

Vue.prototype.Utils = Utils
// Vue.prototype.$http = http;  // http 请求 请写在api文件夹中
Vue.config.productionTip = false;
Vue.component("Page", Page);
Vue.component("PageDetail", PageDetail);
Vue.component("WhiteBox", WhiteBox);
Vue.component("PermissionPage", PermissionPage);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
