<style scoped lang="scss">

@import "~@a/style/variables.scss";


.prod-brand {
  padding: 0 16px;
  background-color:#009B70;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  > i {
    font-size: 20px;
  }
}

@keyframes slide-in-left{
  0% {
    visibility:visible;
    transform:translate3d(-100%,0,0);
  } to {
      transform:translateZ(0);
    }
}
.slide-in-left-enter-active {
  animation:slide-in-left .3s ease-in;
}
.slide-in-left-leave-active{
  animation:slide-in-left .3s ease-in reverse;
}

.product-list {
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  width: 260px;
  z-index: 2000;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-right: $border-base-light;
  /*transition: all 0.5s ease-in-out;*/
  color: $text-primary;
  .title {
    font-size: 14px;
    font-weight: bold;
    margin: 16px 16px 10px;
    color: $text-primary;
    line-height: 20px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    flex: 0 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    cursor: pointer;


    .icon{
      margin-bottom: 8px;
    }
    .name {
      line-height: 1;
    }

  }
}
.product-list li.active a, .product-list li a:hover {
  background: #e9f6fd !important;
  cursor: pointer;
}
.product-list li.active a {
  cursor:default !important;
}
.product-list li a {
  width: 99px;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #666;
  padding: 10px;
  border-radius: 4px;
}
.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  p{
    line-height: 20px;
    margin: 0 0 10px;
    color: #333;
  }
  .content-text {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
  }
}
.overlay {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.2);
  opacity: 0;
  z-index: 1029;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}
</style>
<template>
  <div>
    <div class="prod-brand" @click.stop="showProduct=!showProduct">
      <i :class="!showProduct?'ycsk-grid':'yv-icon-close'"/>
    </div>

    <transition name="slide-in-left">
      <div ref="productList" v-show="showProduct" class="product-list sideProduct">
        <div v-if="productLines.length">
          <div class="title">已开通业务</div>
          <ul @contextmenu.prevent>
            <li class="item" :class="item.code==='AI'?'active':''" v-for="(item, index) in productLines" :key="index"
                @click="handleChange(item.linkPath, item.code,true,item.accessFlag,item.name)">
              <a href="#">
                <img class="icon" :src="productLineIcon(item.code)" :alt="item.name">
                <span class="name">{{ item.name }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="unProductLines.length">
          <div class="title">未开通业务</div>
          <ul @contextmenu.prevent>
            <li class="item" v-for="(item, index) in unProductLines" :key="index"
                @click="handleChange(item.linkPath, item.code,false,item.accessFlag,item.name)">
              <a href="#" >
                <img class="icon" :src="productLineIcon(item.code)" :alt="item.name">
                <span class="name">{{ item.name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <div class="overlay" ref="overlay"></div>
    <yv-dialog
        :visible.sync="dialogVisible"
        :modal-append-to-body="true"
        >
      <div class="content">
        <yv-image
            style="width: 170px; height: 158px"
            :src="img"
            ></yv-image>
        <p class="content-text">你暂时没有{{name}}的管理权限！</p>
        <p class="tips">请联系你的客户主账号，进行项目权限分配。</p>
      </div>
      <div slot="footer" class="dialog-footer">
      </div>
    </yv-dialog>
  </div>
</template>

<script>
import EnvironmentConfig from "@api/environment";
import {mapGetters} from "vuex";
import AuthApi from "@api/auth";
import lockImg from "@a/imgs/lock.png"
export default {
  name: "ProductLine",
  props: {
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      img:lockImg,
      name:'',
      dialogVisible:false,
      showProduct: false,
      codeIconMap: {
        CLOUD_BIZ:'ec',
        CLOUD_CP: 'ec', //基础云
        CSK: 'csk', //容器云
        CR: 'crs', //容器镜像
        CL: 'log', //日志服务
        AI: 'ai', //银汉智算
        HPC: 'hpc', //原力超算
        OSS: 'oss', //对象存储
        CDN: 'cdn', //CDN服务
        CDS: 'cds', //云探CDS
        CFS: 'cfs', //文件存储
      },
      codeUrlMap: {
        CLOUD_BIZ:'bp', //基础云业务
        CLOUD_CP: 'cp', //基础云
        CSK: 'csk', //容器云
        CR: 'crs', //容器镜像
        CL: 'cls', //日志服务
        AI: 'ai', //银汉智算
        HPC: 'hpc', //原力超算
        OSS: 'oss', //对象存储
        CDN: 'cdn', //CDN服务
        CDS: 'cds', //云探CDS
        CFS: 'cfs', //文件存储
      },
      unProductLines:[],
      productLines:[]
    }
  },
  watch:{
    'showProduct':{
      handler:function (val){
        if(val){
          this.$refs.overlay.style.opacity = 1
          this.$refs.overlay.style.visibility = "visible"
        }else{
          this.$refs.overlay.style.opacity = 0
          this.$refs.overlay.style.visibility = "hidden"
        }
      }
    }
  },
  created() {
    this.getBusinessList()
  },
  mounted() {
    document.addEventListener('click', (e) => {
      const dialog = document.querySelector('.yv-dialog__wrapper')
      const close = document.querySelector('.content')
      const close2 = document.querySelector('.yv-dialog__close')
      const close3 = document.querySelector('.yv-dialog')
      const close4 = document.querySelector('.yv-image__inner')
      const close5 = document.querySelector('.yv-dialog__footer')
      const close6 = document.querySelector('.content-text')
      const close7 = document.querySelector('.tips')
      if(e.target!== dialog && e.target!== close && e.target!== close2 && e.target!== close3&& e.target!== close4&&
          e.target!== close5&&e.target!== close6&&e.target!== close7){
        if( this.$refs.productList && !this.$refs.productList.contains(e.target)) {
          this.showProduct = false
        }
      }
    })
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        this.showProduct = false
      }
    })
  },
  methods: {
    getBusinessList(){
      AuthApi.getBizMenu().then(data =>{
        const businessList = data?.businessItems
        if(businessList&&Array.isArray(businessList)){
          this.unProductLines = businessList.filter(item => !item.opened)
          if(this.user.mainAccount){
            this.productLines = businessList.filter(item=> item.opened && 'BIZ_OVERVIEW' !== item.code )
          }else{
            this.productLines = businessList.filter(item=> item.opened&&item.accessFlag&&'BIZ_OVERVIEW' !== item.code)
            if(!this.productLines.filter(it=>it.code==='CLOUD_BIZ').length){
              const cloudBiz = businessList.filter(item => item.code==='CLOUD_BIZ')
              this.productLines = cloudBiz.concat(this.productLines)
            }
          }
        }
      })
    },
    productLineIcon(code){
      return location.protocol + "//static.yovole.com/yv/fonts/pi/" + this.codeIconMap[code] + ".svg"
    },
    handleChange(url, code, flag,accessFlag,name) {
      if (code === 'AI') {
        return
      }
      const cb = EnvironmentConfig.ai.ui_root
      const urlKey = this.codeUrlMap[code]
      if(flag){
        let uiRoot = EnvironmentConfig[urlKey].ui_root
        const cloudOverview = code === 'CLOUD_BIZ' ? '#ProjectOverview' : ''
        if(code === 'CLOUD_BIZ'){
          window.location.href =  `${EnvironmentConfig['cp'].ui_root}` + cloudOverview
        }else{
          window.location.href = `${uiRoot}?callback=${encodeURIComponent(cb)}` + cloudOverview
        }
      }else{
        if(!accessFlag&&!this.user.mainAccount){
          this.dialogVisible=true
          this.name = name
          return;
        }
        let uiRoot = EnvironmentConfig['bp'].ui_root
        if(code==='HPC' || code==='AI'){
          window.location.href = `${uiRoot}` + '/product-service-apply.html?serviceType=' + code;
        }else if(code==='CL'){
          window.location.href = `${EnvironmentConfig['cls'].ui_root}` + '/';
        }else{
          window.location.href = `${uiRoot}` + '/service-open.html?serviceType=' + code;
        }
      }
    },
  },
}
</script>

