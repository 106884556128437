export default [
  {
    path: '/instance/gpu',
    name: 'InstanceGpu',
    component: () => import(/* webpackChunkName: "instance" */ "@/views/instance/gpu"),
    meta: {title: 'GPU容器实例'}
  },
  {
    path: '/instance/cpu',
    name: 'InstanceCpu',
    component: () => import(/* webpackChunkName: "instance" */ "@/views/instance/cpu"),
    meta: {title: 'CPU容器实例'}
  },
  {
    path: '/instance/oplog',
    name: 'InstanceOplog',
    component: () => import(/* webpackChunkName: "instance" */ "@/views/instance/oplog"),
    meta: {title: '操作日志', activeMenu: '/instance'}
  }
]
