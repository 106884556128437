<template>
  <div class="white-box" :class="{'no-padding':noPadding}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WhiteBox",
  props: {
    noPadding: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.white-box {
  background-color: #ffffff;
  // box-shadow: $white-box-shadow;
  border-radius: 6px;
  &:not([class*="no-padding"]) {
    padding: 16px;
  }
  + .white-box {
    margin-top: 16px;
  }
}
</style>
