import {PAGER_EM} from '@/util/constant'
import {QOS_CONFIG} from '@/util/constant'

export default {
  dateFormatter: function (time, format = '{y}-{m}-{d} {h}:{i}:{s}') {
    let date
    if (time) {
      if (time instanceof Date) {
        date = time
      } else if (typeof time === 'number') {
        const timestamp = Number((time.toString() + '000').substr(0, 13))
        date = new Date(timestamp)
      } else if (typeof time === 'string') {
        date = new Date(time)
      } else {
        return null
      }
    } else {
      return null
    }


    if (date.toString() === 'Invalid Date') return null
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds()
    }
    const timeStr = format.replace(/{(y|m|d|h|i|s)+}/g, (result, key) => {
      let value = formatObj[key]
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return timeStr
  },
  flowFormatObj: function (bytes, unit, factor) {
    const obj = {}
    if (!bytes || bytes === 0) return {size: 0, unit: 'Byte'}
    const k = factor || 1024
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    if (unit) {
      const i = sizes.indexOf(unit)
      if (i !== -1) {
        obj.size = (bytes / Math.pow(k, i)).toFixed(i === 0 ? 0 : 2)
        obj.unit = sizes[i]
      } else {
        obj.size = bytes
        obj.unit = unit
      }
      return obj
    } else {
      const i = Math.ceil(Math.log(bytes) / Math.log(k))
      if ((bytes / Math.pow(k, i)) < 1) { // 不到一个单位，降一个单位
        obj.size = (bytes / Math.pow(k, i - 1)).toFixed((i - 1) === 0 ? 0 : 2)
        obj.unit = sizes[i - 1]
        return obj
      }
      obj.size = (bytes / Math.pow(k, i)).toFixed(i === 0 ? 0 : 2)
      obj.unit = sizes[i]
      return obj
    }
  },
  fileSizeFormat: function (quantity, zeroUnit, factor) {
        if (!quantity || quantity === 0 || quantity < 0) {
            if (zeroUnit) {
                return '0 ' + zeroUnit
            } else {
                return '0 Byte'
            }
        }
        const k = factor || 1024
        const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.ceil(Math.log(quantity) / Math.log(k))
        if ((quantity / Math.pow(k, i)) < 1) {
            return (quantity / Math.pow(k, i - 1)).toFixed((i - 1) === 0 ? 0 : 2) + ' ' + sizes[i - 1]
        } else {
            return (quantity / Math.pow(k, i)).toFixed(i === 0 ? 0 : 2) + ' ' + sizes[i]
        }
    },
    fileSizeFormatGb: function (quantity, factor) {
        if (!quantity || quantity === 0 || quantity < 0) {
            return '0'
        }
        const k = factor || 1024
        const i = 3
        if ((quantity / Math.pow(k, i)) < 1) {
            return (quantity / Math.pow(k, i - 1)).toFixed((i - 1) === 0 ? 0 : 2)
        } else {
            return (quantity / Math.pow(k, i)).toFixed(i === 0 ? 0 : 2)
        }
    },
  fileSizeFormat1: function (quantity, zeroUnit, factor, point) {
    if (!quantity || quantity === 0 || quantity < 0) {
      if (zeroUnit) {
        return ['0 ', zeroUnit]
      } else {
        return ['0', 'Byte']
      }
    }
    const k = factor || 1024
    const p = (point === 0 || point) ? point : 2
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.ceil(Math.log(quantity) / Math.log(k))
    if ((quantity / Math.pow(k, i)) < 1) {
      return [(quantity / Math.pow(k, i - 1)).toFixed((i - 1) === 0 ? 0 : p) + '', sizes[i - 1]]
    } else {
      return [(quantity / Math.pow(k, i)).toFixed(i === 0 ? 0 : p) + '', sizes[i]]
    }
  },
  // 构造FormData对象
  formDataParams: function (filterArray, sortArray, pageObject) {
    const resParamsFilter = new FormData()
    const sortFilter = []
    for (const key in pageObject) {
      resParamsFilter.append(PAGER_EM[key], pageObject[key])
    }
    sortArray.forEach((item) => {
      sortFilter.push({'key': item.key, 'value': item.sort})
    })
    if (sortFilter.length > 0) {
      resParamsFilter.append('sorts', JSON.stringify(sortFilter))
    }
    return resParamsFilter
  },
  mergeParams(params, packageParamsFilter) {
    for (const key in packageParamsFilter) {
      params.append(key, packageParamsFilter[key])
    }
    return params
  },
  formatNumber: function (number) {
    const num = parseInt(number).toString()
    const len = num.length
    if (len <= 3) return num
    const remainder = len % 3
    return remainder > 0 ? num.slice(0, remainder) + ',' + num.slice(remainder, len).match(/\d{3}/g).join(',')
      : num.slice(remainder, len).match(/\d{3}/g).join(',')
  },
  negative2zero: function (num) {
    return num < 0 ? 0 : num
  },

  join(array, key, spl, def) {
    var datas = []
    if (array) {
      array.forEach(function (ele) {
        datas.push(ele[key])
      })
    }
    if (datas.length == 0 && def) {
      return def
    }
    return datas.join(spl)
  },

  getFromJsonStr(jsonStr, key) {
    if (jsonStr) {
      var _flavorConfig = JSON.parse(jsonStr);
      return _flavorConfig[key] || ''
    }
    return ''
  },
  isQosUnlimit(v) {
    return v && v === QOS_CONFIG.UNLIMIT
  },
  toThousands(num) {
    num = (num || 0).toString()
    var result = ''
    if (num.split(".")[1]) {
      var numInt = num.split('.')[0],
        numFlo = num.substring(num.indexOf('.'))
      result = this.toThousandsOfInt(numInt) + numFlo;
    } else {
      result = this.toThousandsOfInt(num)
    }
    return result
  },
  toThousandsOfInt(num) {
    num = (num || 0).toString()
    var result = ''
    if (num.indexOf(',') < 0) {
      while (num.length > 3) {
        result = ',' + num.slice(-3) + result
        num = num.slice(0, num.length - 3)
      }
      if (num) {
        result = num + result
      }
    } else {
      result = num
    }

    return result;
  },
  toComputationTime(ts){
      if (ts) {
        var  DAY =  24 * 60 * 60
        var HOUR =  60 * 60;
        var MINUTE = 60;
        var day = Math.floor(ts / DAY);
        var hour = Math.floor(ts % DAY / HOUR);
        if(day>=1){
            hour = day * 24 + hour;
        }
        var min = Math.floor(ts % DAY % HOUR / MINUTE);
        var sec = ts % DAY % HOUR % MINUTE;
        var h = hour + "";
        var m = min + "";
        var s = sec + "";
        if(hour<10){
            h = "0" + h;
        }
        if(min<10){
            m = "0" + m;
        }
        if(sec<10){
            s = "0" + s;
        }
        return h + ":" + m + ":" + s;
    } else {
      return"00:00:00"
    }
  },
  toComputationHhTime(ts){
      if (ts) {
        var  DAY =  24 * 60 * 60
        var HOUR =  60 * 60;
        var MINUTE = 60;
        var day = Math.floor(ts / DAY);
        var hour = Math.floor(ts % DAY / HOUR);
        if(day>=1){
            hour = day * 24 + hour;
        }
        var min = Math.floor(ts % DAY % HOUR / MINUTE);
        var h = hour + "";
        var m = min + "";
        if(hour<10){
            h = "0" + h;
        }
        if(min<10){
            m = "0" + m;
        }
        return h + "小时" + m + "分钟" ;
    } else {
      return""
    }
  },
  numberFormat:function(number, decimals, dec_point, thousands_sep) {
    if(decimals == undefined) decimals = 2;
    if(dec_point == undefined) dec_point = '.';
    if(thousands_sep == undefined) thousands_sep = ',';
    var r = number.toFixed(decimals);
    var nStr = r + '';
    var x = nStr.split(dec_point);
    var x1 = x[0];
    var x2 = x.length > 1 ? dec_point + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  },
    /**
     * 根据小时自动升级或降级的换算单位方法
     *   按照阈值换算，入参*阈值的倒数（注意，小于1分钟的都按照秒表示，小于0.01秒返回0）
     *   月以上单位向下取整
     *   超过99年返回99+年
     *
     *   ex. 0.0001 -> 0.36秒
     *       0.1 -> 6分钟
     *       23 -> 23小时
     *       26 -> 1.08天
     *       840 -> 1个月（向下取整）
     *       9640 -> 1年（向下取整）
     *       864000 -> 99+年
     * @param hour 正数
     * @returns {'time': 时间 ,'unit': 单位}
     */
  timeAutoConvert:function(hour) {
    if (hour < 0) return {'time': hour ,'unit': ''}
    if (hour >= 24*30*12*99) return {'time': '99+' ,'unit': '年'}
    const units = ['秒','分钟','小时','天','个月','年']
    const hourThreshold = [1/3600,1/60,1,24,24*30,24*30*12]
    let _ts = 6
    for (let i = 0; i < hourThreshold.length; i++) {
        if (hour < hourThreshold[i]) {
            _ts = i
            break
        }
    }
    let idx = 1
    if (_ts > 1) {
        idx = _ts
    }
    let time = hour * 1 / hourThreshold[idx - 1]
    if (idx < 3) {
      time = time < 0.01 ? 0 : time.toFixed(2)
    } else {
      time = parseInt(time)
    }
    return {'time': time ,'unit': units[idx - 1]}
  }
}
