export default [
    {
        path: "/resource/quota",
        name: "ResourceQuota",
        component: () => import(/* webpackChunkName: "resource" */ "@/views/resource/quota/index"),
        meta: {title: "客户配额", activeMenu: "/resource/quota"},
    },
    {
        path: '/resource/metering-instance',
        name: 'ResourceMetering',
        component: () => import(/* webpackChunkName: "resource" */ "@/views/resource/metering-instance"),
        meta: {title: '资源计量', activeMenu: "/resource/metering-instance"},
        access:'mainAccount',
    },
    {
        path: '/resource/metering-instance',
        name: 'ResourceStorageMetering',
        component: () => import(/* webpackChunkName: "resource" */ "@/views/resource/metering-instance"),
        meta: {title: '存储计量', activeMenu: "/resource/metering-instance"},
        access:'mainAccount',
    },
    {
        path: "/resource/package",
        name: "Package",
        component: () => import(/* webpackChunkName: "resource" */ "@/views/resource/package"),
        meta: {title: "可用资源包", activeMenu: "/resource/package"}
    },
    {
        path: "/resource/package/packageRecordList",
        name: "PackageRecordList",
        component: () => import("@/views/resource/package/component/package-record-list"),
        meta: {title: "资源包使用明细", activeMenu: "/resource/package"},
        access:'mainAccount',
    },
    {
        path: "/resource/metering-instance",
        name: "AccountView",
        component: () => import("@/views/resource/metering-instance/component/AccountView"),
        meta: {title: "用户个人网盘存储", activeMenu: "/resource/metering-instance"},
        access:'mainAccount',
    },
]
