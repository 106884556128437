export default [
    {
        path: "/tensortflow",
        name: "TensorFlow",
        component: () => import("@/views/model/tensorflow/index"),
        meta: {title: "TensorFlow", activeMenu: "/tensortflow"},
    },{
        path: "/tensortflow-addtask",
        name: "TensorFlowAddTask",
        component: () => import("@/views/model/tensorflow/add-task"),
        meta: {title: "TensorFlow", activeMenu: "/tensortflow"},
    },{
        path: "/tensortflow-addInfertask",
        name: "TensorFlowAddInferTask",
        component: () => import("@/views/model/tensorflow/add-infer-task"),
        meta: {title: "TensorFlow", activeMenu: "/tensortflow"},
    },{
        path: "/tensortflow-detail",
        name: "TensorFlowDetail",
        component: () => import("@/views/model/tensorflow/detail"),
        meta: {title: "TensorFlow", activeMenu: "/tensortflow"},
    },{
        path: "/pytorch",
        name: "PyTorch",
        component: () => import("@/views/model/pytorch/index"),
        meta: {title: "PyTorch", activeMenu: "/pytorch"},
    },{
        path: "/pytorch-addtask",
        name: "PyTorchAddTask",
        component: () => import("@/views/model/pytorch/add-task"),
        meta: {title: "PyTorch", activeMenu: "/pytorch"},
    },{
        path: "/pytorch-addInfertask",
        name: "PyTorchAddInferTask",
        component: () => import("@/views/model/pytorch/add-infer-task"),
        meta: {title: "PyTorchAddTask", activeMenu: "/pytorch"},
    },{
        path: "/pytorch-detail",
        name: "PyTorchDetail",
        component: () => import("@/views/model/pytorch/detail"),
        meta: {title: "PyTorch", activeMenu: "/pytorch"},
    }
]
