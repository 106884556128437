export default [
  {
    path: '/asset/dataset',
    name: 'AssetDataset',
    component: () => import(/* webpackChunkName: "asset" */ "@/views/asset/dataset"),
    meta: {title: 'Dataset'}
  },
  {
    path: '/asset/algorithm',
    name: 'AssetAlgorithm',
    component: () => import(/* webpackChunkName: "asset" */ "@/views/asset/algorithm"),
    meta: {title: 'Algorithm'}
  }
]
