<template>
  <div class="sidebar-container">
    <yv-scrollbar wrap-class="scrollbar-wrapper">
      <yv-menu
        class="hpc-menu"
        :default-active="activeMenu"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        open-submenu-text-color="#626466"
        mode="vertical"
        @select="selectMenu"
      >
        <template v-for="item in menuList">
          <router-link :key="item.path" v-if="!item.hide && !item.children" :to="item.path">
            <yv-menu-item :index="item.path" v-if="!item.hide && !item.children">
              <template>
                <i :class="['menu-icon ai', 'ai-' + item.iconName, activeMenu === item.path && 'active']" />
                <span slot="title" :class="['menu-text', activeMenu === item.path && 'active']">{{ item.text }}</span>
              </template>
            </yv-menu-item>
          </router-link>
          <yv-submenu :key="item.path" :index="item.path" v-if="!item.hide && item.children" class="ai-sidebar-submenu">
            <template slot="title">
              <i :class="['submenu-menu-icon ai', 'ai-' + item.iconName, activeMenu === item.path && 'active']" />
              <span class="submenu-menu-text">{{item.text}}</span>
            </template>
            <router-link v-for="childrenItem in item.children" :key="childrenItem.path" :to="childrenItem.path">
              <yv-menu-item  :index="childrenItem.path" v-if="!childrenItem.hide">
                <span slot="title" :class="['menu-text', activeMenu === childrenItem.path && 'active']">
                  {{ childrenItem.text }}</span>
              </yv-menu-item>
            </router-link>
          </yv-submenu>
          <yv-divider :key="item.path" v-if="item.path === '/asset/dataset'" />
        </template>
      </yv-menu>
    </yv-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import variables from "@a/style/variables.scss";
import store from "@/store";

export default {
  name: "Sidebar",
  data() {
    return {
      activeMenu: '',
      menuList: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.active = this.$route.meta.activeMenu
        this.activeMenu = this.active ? this.active : this.$route.path
        const mainAccount = store.state.user.user.mainAccount
        const cciEnable = store.state.user.user.aiSession && store.state.user.user.aiSession.cciEnable
        const dsEnable = store.state.user.user.aiSession && store.state.user.user.aiSession.dsEnable
        if (this.$route.name === 'User') {
            this.menuList = [
                {
                    path: '/user',
                    iconName: 'user',
                    text: '智算用户'
                }
            ]
        } else {
            this.menuList = [
                {
                    path: '/workbench',
                    iconName: 'workbench',
                    text: '工作台'
                },
                {
                  path: '/model-overview',
                  iconName: 'dataset',
                  text: '模型广场',
                  hide: !dsEnable,
                },
                {
                    path: '/instance',
                    iconName: 'container',
                    text: '容器实例',
                    children: [
                      {
                        path: '/instance/gpu',
                        text: 'GPU容器实例'
                      },
                      {
                        hide: !cciEnable,
                        path: '/instance/cpu',
                        text: 'CPU容器实例'
                      }
                    ]
                },
                // {
                //     path: '/model',
                //     iconName: 'model',
                //     text: '模型训练',
                //     children: [
                //         {
                //             path: '/tensortflow',
                //             text: 'TensorFlow'
                //         },
                //         {
                //             path: '/pytorch',
                //             text: 'PyTorch'
                //         }
                //     ]
                // },
                {
                    path: '/ssh',
                    iconName: 'ssh',
                    text: 'SSH密钥',
                },
                {
                    path: '/netdisk',
                    iconName: 'hd',
                    text: '我的网盘'
                },
                {
                    path: '/image',
                    iconName: 'image',
                    text: '镜像',
                    children: [
                        {
                            path: '/image/gpu',
                            text: 'GPU镜像管理'
                        },
                        {
                            hide: !cciEnable,
                            path: '/image/cpu',
                            text: 'CPU镜像管理'
                        }
                    ]
                },

                {
                    path: '/asset/dataset',
                    iconName: 'dataset',
                    text: '数据集'
                },

                {
                    path: '/resource/package',
                    iconName: 'resource',
                    text: '可用资源包'
                },

                {
                    path: '/resource/metering-instance',
                    iconName: 'statistics',
                    hide: !mainAccount,
                    text: '资源计量'
                },






                // {
                //     path: '/dataset',
                //     iconName: 'dataset',
                //     text: '公共数据集'
                // },
                // {
                //     path: '/resource',
                //     iconName: 'resource',
                //     text: '资源计量',
                //     hide: !mainAccount,
                //     children: [
                //         {
                //             path: '/resource/quota',
                //             text: '客户配额'
                //         },
                //         {
                //             path: '/resource/metering-instance',
                //             text: '容器实例计量'
                //         },
                //         {
                //             path: '/resource/metering-storage',
                //             text: '存储计量'
                //         }
                //     ]
                // },
                //
                // {
                //     path: '/asset',
                //     iconName: 'dataset',
                //     text: 'AI资产管理',
                //     children: [
                //         // {
                //         //     path: '/asset/algorithm',
                //         //     text: '算法管理'
                //         // },
                //         // {
                //         //     path: '/asset/aimodel',
                //         //     text: '模型管理'
                //         // },
                //         {
                //             path: '/asset/dataset',
                //             text: '数据集管理'
                //         }
                //     ]
                // },
                // {
                //   path: '/share',
                //   iconName: 'share',
                //   text: '共享中心'
                // }
            ]
        }
        this.$store.dispatch("app/setMenu", this.menuList);
      }
    }
  },
  computed: {
    ...mapGetters(["sidebar"]),
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    }
  },
  methods: {
    selectMenu (indexPath) {
      this.activeMenu = indexPath
    }
  }
};
</script>

<style lang="scss">
@import "~@a/style/variables.scss";
.sidebar-container {
  float: left;
  transition: width 0.28s;
  width: $sidebar-width;
  background: $white;
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.06);
  height: calc(100vh - #{$header-height});
  position: absolute;
  font-size: 0px;
  top: $header-height;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .yv-scrollbar__bar.is-vertical {
    right: 0;
  }

  .yv-scrollbar {
    height: 100%;
  }

  .yv-menu {
    border: none;
    height: 100%;
    width: 100% !important;
    &.hpc-menu {
      margin: 6px 0;
    }
    .yv-menu-item {
      box-shadow: 3px 0px 0px 0px #24a56b;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      margin: 2px 0;
      &:hover {
        // background-color: transparent;
        .menu-icon, .menu-text {
          color: $primary;
        }
      }

      &.is-active {
        background-color: $primary-color-1;
        border-left: 3px solid $primary;

        > div {
          margin-left: -3px !important;
        }
      }
      .menu-icon {
        vertical-align: middle;
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
      }
      .menu-text {
        margin-top: 5px;
        font-size: 14px;
        line-height: normal;
        color: $text-secondary;
      }
      .active {
        color: $primary;
      }
    }
  }
}

#app {
  .hideSidebar {
    .sidebar-container {
      width: $sidebar-min-width !important;
    }

    .main-container {
      margin-left: $sidebar-min-width;
    }

    .submenu-title-noDropdown {
      padding: 0 !important;
      position: relative;

      .yv-tooltip {
        padding: 0 !important;

        .svg-icon {
          margin-left: 20px;
        }

        .sub-yv-icon {
          margin-left: 19px;
        }
      }
    }

    .yv-submenu {
      overflow: hidden;
      & > .yv-submenu__title {
        // 隐患：存在submenu时会存在问题
        /*padding: 0 !important;*/

        .svg-icon {
          margin-left: 20px;
        }

        .sub-yv-icon {
          margin-left: 19px;
        }
      }
      &.ai-sidebar-submenu {
        .yv-submenu__title {
          border-left: 3px solid transparent;
          border-right: 3px solid transparent
        }
         .submenu-menu-icon {
          vertical-align: middle;
          margin-right: 5px;
          width: 24px;
          text-align: center;
          font-size: 18px;
          color: $text-secondary;
        }
        .submenu-menu-text {
          margin-top: 5px;
          font-size: 14px;
          line-height: normal;
          color: $text-secondary;
        }
        .yv-submenu__icon-arrow {
          color: $text-secondary;
          margin-top: -4px;
        }
        .menu-text {
          margin-left: -5px;
        }
        &.is-opened {
          .submenu-menu-icon, .submenu-menu-text, .yv-submenu__icon-arrow {
            color: #626466;
          }
        }
      }
    }

    .yv-menu--collapse {
      .yv-submenu {
        & > .yv-submenu__title {
          & > span {
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            display: inline-block;
          }
        }
      }
    }
  }

  .withoutAnimation {
    .main-container,
    .sidebar-container {
      transition: none;
    }
  }
}

// when menu collapsed
.yv-menu--vertical {
  & > .yv-menu {
    .svg-icon {
      margin-right: 16px;
    }

    .sub-yv-icon {
      margin-right: 12px;
      margin-left: -2px;
    }
  }

  .nest-menu .yv-submenu > .yv-submenu__title,
  .yv-menu-item {
    &:hover {
      // you can use $subMenuHover
      //background-color: $menuHover !important;
    }
  }

  // the scroll bar appears when the subMenu is too long
  > .yv-menu--popup {
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}
</style>
