class Enum {
    add (key, value) {
        this[key] = value
        return this
    }
}

export const PAGER_EM = new Enum().add('pageNumber', 'curPage')
    .add('pageSize', 'perPage');

export const SERVICE_USE_TYPE = new Enum()
    .add('POC', { code: 'POC', label: 'POC'})
    .add('PRO', { code: 'PRO', label: '正式'})

export const PACKAGE_EM = new Enum()
    .add('AI_STORAGE_PACKAGE', '存储容量包')
    .add('AI_GPU_PACKAGE', 'GPU卡时算力包')
    .add('AI_CPU_PACKAGE', 'CPU核时算力包')

export const USER_BUSINESS = new Enum()
    .add("BIZ_OVERVIEW", { code: "BIZ_OVERVIEW", configUrl: "cloud", iconClass: "yv-icon-dashboard" })
    .add("CLOUD_BIZ", { code: "CLOUD_BIZ", configUrl: "cloud", iconClass: "pi pi-host" })
    .add("OSS", { code: "OSS", configUrl: "oss", iconClass: "pi pi-oss" })
    .add("CDN", { code: "CDN", configUrl: "cdn", iconClass: "pi pi-cdn" })
    .add("CDS", { code: "CDS", configUrl: "cds", iconClass: "pi pi-cds" })
    .add("HPC", { code: "HPC", configUrl: "hpc", iconClass: "pi pi-hpc" })
    .add("AI", { code: "AI", configUrl: "ai", iconClass: "pi pi-ai" })
    .add("CL", { code: "CL", configUrl: "cls", iconClass: "pi pi-log-service" })
    .add('CR', { code: 'CR', configUrl: 'crs', iconClass: 'pi pi-container-image' })



export const QOS_CONFIG = {
    UNLIMIT: -1,
    UNLIMIT_NAME: '--',
};

export const SSH_STATUS = new Set()
    .add({code: "NOTUSE", label: "未使用", type: "warning" })
    .add({code: "USING", label: "使用中", type: "success" })

export const INSTANCE_STATUS = {
    STOP: 'Stop',
    RUNNING: 'Running',
    DEPLOY: 'Deploy',
    PENDING: 'Pending',
    ERROR: 'Error',
}
export const INSTANCE_TASK_STATUS = {
    // 任务进行中的状态， 此状态下容器不能有操作
    CREATING: 'CREATING',
    STARTING: 'STARTING',
    STOPPING: 'STOPPING',
    DELETING: 'DELETING',
    CONFIG_CHANGING: 'CONFIG_CHANGING'//配置变更中
}

export const INSTANCE_STATUS_SET = new Set()
  .add({code: 'Stop', label: '已关机', type: 'text'})
  .add({code: 'Running', label: '运行中', type: 'success'})
  .add({code: 'Deploy', label: '部署中', type: 'warning'})
  .add({code: 'Pending', label: '排队中', type: 'warning'})
  .add({code: 'Error', label: '失败', type: 'danger'})
  .add({code: 'ISOLATED', label: '已隔离', type: 'danger'})

export const INSTANCE_STATUS_WORKBENCH = new Set()
  .add({code: 'Stop', label: '已关机', type: 'text'})
  .add({code: 'Running', label: '运行中', type: 'success'})
  .add({code: 'ISOLATED', label: '已隔离', type: 'danger'})


export const INSTANCE_BIZ_STATUS_SET = new Set()
  .add({code: 'ACTIVE', label: '正常', type: 'success'})
  .add({code: 'ISOLATED', label: '已隔离', type: 'danger'})


export const INSTANCE_TASK_STATUS_SET = new Set()
  .add({code:'CREATING', label: '排队中', type: 'warning'})
  .add({code:'STARTING', label: '开机中', type: 'warning'})
  .add({code:'STOPPING', label: '关机中', type: 'warning'})
  .add({code:'DELETING', label: '删除中', type: 'warning'})
  .add({code:'CONFIG_CHANGING', label: '配置变更中', type: 'warning'})

export const IMAGE_STATUS_SET = new Set()
  .add({code:'', label: '可用', type: 'success'})
  .add({code:'CREATING', label: '镜像保存中', type: 'loading'})
  .add({code:'ERROR', label: '镜像创建失败', type: 'danger'})

export const VP_LOGO = new Enum()
    .add('VP-YOVOLE', { path: 'yv',subject:true})
    .add('VP-YOVOLE', { path: 'yv',subject:true})
    .add('VP-PHADCALC', { path: 'phadcalc',subject:false})
    .add('VP-ATAC', { path: 'atac',subject:true})
    .add('VP-LANTUXS', { path: 'lantuxs',subject:false})
