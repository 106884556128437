<template>
  <div class="page-wrap">
    <div class="page-header">
      <div class="page-title">
        {{ title }}
      </div>
    </div>
    <div class="page-main">
      <yv-scrollbar>
        <WhiteBox>
        <div class="permission-header">
          <img :src="PermissionPng" width="250"/>
          <p class="header-title">智算服务部分功能已受限</p>
          <p>你归属的客户账号目前已处于
            <span class="text-danger">欠费状态</span>，
          </p>
          <p>请尽快联系客户主账号进行充值。</p>
        </div>
        <div class="permission-content">
          <yv-descriptions
            :column="1"
            :labelStyle="{width: '100px'}"
            :contentStyle="{flex: '1'}"
            :colon="false"
            size="small"
          >
            <yv-descriptions-item label="云账号">
                {{user.name}}（{{user.loginName}}）
            </yv-descriptions-item>
            <yv-descriptions-item label="所属客户">
                {{user.customer.name}}
                <span class="text-secondary ml4">({{user.customer.sn}})</span>
            </yv-descriptions-item>
          </yv-descriptions>
        </div>
      </WhiteBox>
      </yv-scrollbar>
    </div>
  </div>
</template>

<script>
import PermissionPng from "@a/imgs/no-permission.png";
import {mapGetters} from "vuex";
export default {
  name: "PermissionPage",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["user"])
  },
  data () {
    return {
      PermissionPng: PermissionPng
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.page-wrap {
  .page-header {
    position: relative;
    display: flex;
    align-items: center;
    height: $header-height;
    padding: 0 16px;
    background-color: #ffffff;
    // box-shadow: $white-box-shadow;
    border-bottom:$border-base;
    min-height: 51px;
    .page-title {
      font-size: 16px;
      color: $text-primary;
      font-weight: 600;
      margin-right: 16px;
    }
  }

  .page-main {
    ::v-deep .yv-scrollbar__wrap {
      padding: 16px 16px 0 16px;
      box-sizing: border-box;
      height: calc(100vh - #{$header-height * 2});
      overflow-x: hidden;
      .padding-bottom__space{
        padding-bottom: 16px;
      }
    }
    .permission-header {
      width: 300px;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      color: $text-secondary;
       img{
          margin: 0 auto;
        }
      .header-title {
        font-size: 22px;
        font-weight: bold;
        color: $text-primary;
        margin-bottom: 10px;
      }
    }
    .permission-content {
      background-color: $bg-base;
      border-radius: 4px;
      padding: 24px;
      width: 600px;
      margin: 32px auto;
      ::v-deep .yv-descriptions__body {
        background-color: $bg-base;
      }
    }
  }
}
</style>
