<template>
  <div class="header-container">
    <div class="header">
      <div class="navbar">
        <ProductLine/>
        <div class="navbar-brand">
          <span class="logo-figure">
           <img :src="vpLogoPath()" alt="">
          </span>
          <span class="logo-text">智算</span>
        </div>
        <div v-if="!isInvited" :class="['regions', showBackWorkspace && 'selected']">
          <template>

              <span class="yv-dropdown-link" @click="backWorkspace">
                <i class="hpc hpc-workbench yv-icon--left"></i>
                我的工作台
              </span>
          </template>
        </div>

      </div>
      <div class="d-flex">
        <yv-menu mode="horizontal" class="header-menu">
          <yv-menu-item index="1" v-if="user.mainAccount" :class="!showBackWorkspace && 'selected'">
            <router-link to="/user">
              <a onclick="javascript:;"> <i class="ai ai-user" />智算用户</a>
            </router-link>
          </yv-menu-item>
          <yv-menu-item index="2">
            <a @click="linkToVpp('MyBusiness')"><i class="ycsk-home"/>业务概览 </a>
          </yv-menu-item>
          <yv-menu-item index="3" v-if="user.costPerm">
            <a @click="linkToVpp('AccountOverview')"> <i class="yv-icon yv-icon-money" /> 费用 </a>
          </yv-menu-item>
          <yv-menu-item index="4" v-if="user.costPerm">
            <a @click="linkToBp"><i class="ai ai-calculate" />询价 </a>
          </yv-menu-item>
          <yv-submenu ref="accountMenu" index="6" :popper-append-to-body="false">
            <template slot="title">
              <p @mouseenter="mouseAccountMenu"><i class="ai ai-avatar" />{{ user.name }}</p>
            </template>
            <!--            <yv-menu-item>-->
            <!--              <router-link to="./">-->
            <!--                <yv-icon class="yv-icon-lock" />-->
            <!--                修改密码-->
            <!--              </router-link>-->
            <!--            </yv-menu-item>-->
            <yv-menu-item class="business-menu-item">
              <a href="javascript:void(0)" @click="linkToVpp('MyAccountManage')">
                <i class="yv-icon yv-icon-setting" />我的帐号管理
              </a>
            </yv-menu-item>
            <yv-menu-item class="business-menu-item">
              <a href="javascript:void(0)" @click="linkToExport">
                <yv-icon class="yv-icon yv-icon-export"/>我的导出管理
              </a>
            </yv-menu-item>
            <yv-menu-item>
              <a @click="handleLogout">
                <i class="yv-icon yv-icon-logout" />退出
              </a>
            </yv-menu-item>
          </yv-submenu>
        </yv-menu>
        <div class="shortcut">
          <div class="shortcut-icon" @click="linkToHelp">
              <yv-tooltip
              content="帮助中心">
                  <i class="ai ai-shortcut-help"></i>
              </yv-tooltip>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Hamburger from "./Hamburger";
import {mapGetters} from "vuex";
import AuthService from "@api/auth"
import EnvironmentConfig from "@api/environment"
import {USER_BUSINESS,VP_LOGO} from "@util/constant";
import ProductLine from "./ProductLine";
export default {
  name: "Header",
  components: {ProductLine},
  props: {
    isInvited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      USER_BUSINESS: USER_BUSINESS,
      showBackWorkspace: true,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
    ...mapGetters(["user"]),
    businessIcon () {
      return function (item) {
        return this.USER_BUSINESS[item.code] ? this.USER_BUSINESS[item.code].iconClass : ''
      }
    },
    vpLogoPath() {
      return function () {
        if (VP_LOGO[this.user.vppId]) {
          return require('../../assets/imgs/' + VP_LOGO[this.user.vppId].path + '/logo.png')
        } else {
          return require('../../assets/imgs/yh/logo.png')
        }
      }
    },
    isCustomizeVpLogo() {
      return function () {
        if (VP_LOGO[this.user.vppId]&&VP_LOGO[this.user.vppId].subject) {
          return true
        } else {
          return false
        }
      }
    }
  },
  watch: {
      $route: {
          immediate: true,
          handler: function () {
              this.showBackWorkspace = this.$route.name !== 'User'
          }
      },
  },
  methods: {
    linkToExport(){
      window.open(`${EnvironmentConfig.exp.ui_root}`);
    },
    mouseAccountMenu () {
      const elm = this.$refs['accountMenu']
      elm.currentPlacement = 'bottom-end'
    },
    backWorkspace () {
        this.$router.push({name: 'Workbench'})
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    linkToVpp (moduleId) {
      // eslint-disable-next-line max-len
      window.location.href = `${EnvironmentConfig.ubp.api_root}/proxy/web/ui/?vppId=${this.user.platformId}&moduleId=${moduleId}`
    },
    linkToBp () {
      if (this.user.calculatorToken) {
        const defaultParams = { productLineId: 'PRODUCT_LINE_AI' }
        // eslint-disable-next-line max-len
        window.open(`${EnvironmentConfig.cloud.ui_root}/calculator.html?token=${this.user.calculatorToken}&defaultParams=${encodeURI(JSON.stringify(defaultParams))}`, 'calculate')
      }
    },
    linkToHelp () {
      window.open(`${EnvironmentConfig.cloud.help_ui_root}/ai/html/platform/introduction.html`)
      // window.location.href = `${EnvironmentConfig.cloud.help_root}/hpc-doc/platformintroduction.html`
      // window.open('https://cloud.yovole.com/ai-doc/', 'help')
      // window.open(`${EnvironmentConfig.ai.help_root}`, 'help')
      //   const vppId = `${this.user.platformId}`
      //   if(vppId === 'VP-PUBLIC') {
      //       window.open('https://www.yinhanyun.com/ai-doc/', 'help')
      //   } else {
      //       window.open('https://cloud.yovole.com/ai-doc/', 'help')
      //   }
    },
    handleLogout() {
      AuthService.getLogout().then(() => {
        this.$store.dispatch("user/logout");
        const cb = EnvironmentConfig.ai.ui_root
        window.location.href = `${EnvironmentConfig.passport.api_root}/auth/login?callback=${encodeURIComponent(cb)}`
      }).catch(err => {
        this.$message.danger(err.message)
      })
    },
    handleChange (url, code) {
      if (code === 'AI') {
        this.$router.push('/')
        return
      }
      const cb = EnvironmentConfig.ai.ui_root
      const configUrl = this.USER_BUSINESS[code].configUrl
      let uiRoot = EnvironmentConfig[configUrl].ui_root
      const cloudOverview = code === 'CLOUD_BIZ' ? '#ProjectOverview' : ''
      window.location.href = `${uiRoot}?callback=${encodeURIComponent(cb)}` + cloudOverview
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.header {
  height: 50px;
  background-color: rgb(18,18,18);
  color: white;
  line-height: 50px;
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  .navbar {
    display: flex;
    align-items: center;
  }
  .navbar-brand {
    height: 50px;
    display: flex;
    padding: 0 10px;
    position: relative;
    align-items: center;
    background-color: $primary;
    .logo-figure {
      img {
        height: 45px;
      }
    }
    .logo-text {
      display: inline-block;
      font-size: $font-lg;
      color: white;
      &:before {
        content: "▪";
        display: inline-block;
        padding: 0 4px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  &::v-deep .yv-menu--horizontal.yv-menu {
    .account-title {
      margin-right: 10px;
      margin-left: 12px;
    }
    .yv-menu-item,
    .yv-submenu__title {
      height: 50px;
      line-height: 50px;
      border-bottom: none;
      padding: 0 12px !important;
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
      }
      a:hover {
        text-decoration: none;
      }
      &:hover,
      &:hover i {
        color: white;
      }
      i {
        color: rgba(255, 255, 255, 0.8);
        vertical-align: baseline;
        margin-right: 4px;
        font-size: 14px;
        &.yv-submenu__icon-arrow {
          margin-right: 0;
          margin-left: 4px;
        }
      }
      .is-dot {
        right: 8px;
        bottom: 15px;
        top: auto;
        border: none;
        background-color: #fc4700;
        width: 6px;
        height: 6px;
      }
      &.selected {
        background-color: rgba(0, 0, 0, 0.1);
        color: $primary;
        i {
          color: $primary;
        }
      }
    }
    .yv-menu-item,
    .yv-submenu__title{
      &:not(.is-disabled):hover,
      &:not(.is-disabled):focus,
      {
        background-color: rgba(0, 0, 0, .1) !important;
      }
    }
    .yv-submenu {
      display: flex;
      align-items: center;
      &.divider-flag {
        margin-left: 8px;
        &::before {
          content: "";
          width: 1px;
          height: 20px;
          margin-right: 8px;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      .yv-submenu__icon-arrow {
        font-size: $font-sm;
        transform: scale(0.8);
        vertical-align: middle;
        margin-top: 2px;
      }
      .yv-menu-item {
        min-width: 100%;
        [class^=yv-icon-] {
          width: auto;
        }
      }
      [class^=yv-icon-] {
        width: auto;
      }
    }
    .yv-submenu:focus .yv-submenu__title,
    .yv-submenu.is-active .yv-submenu__title {
      color: rgba(255, 255, 255, 0.8);
      border-bottom: none;
    }
  }
  .yv-menu {
    background: none;
    border-bottom: none;
  }
  &::v-deep .yv-menu--horizontal:not(.yv-menu),
  &::v-deep .yv-menu--vertical:not(.yv-menu) {
    .yv-menu--popup {
      border: $border-base;
      min-width: 160px;
      margin-top: 5px;
      .yv-menu-item {
        font-size: $font-base;
        height: 32px;
        line-height: 32px;
        width: 100%;
        &.business-menu-item {
          &:first-child {
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 6px;
            margin-bottom: 6px;
          }
        }

        &:hover {
          background: $bg-base !important;
        }
        a {
          color: $text-regular;
          display: block;
          flex: 1;
          width: 100%;
        }
        i {
          color: $text-regular;
          margin-right: 5px;
          font-size: 14px;
          vertical-align: baseline;
        }
        &.active {
          background-color: #e7f6f2 !important;
          a, i {
            color: $primary;
          }
        }
      }
    }
  }
  &::v-deep .yv-menu-item.is-active,
  &::v-deep .yv-menu-item:hover,
  &::v-deep .yv-submenu__title:hover {
    color: $primary;
    .figure {
      &:before {
        color: $primary;
      }
      i {
        color: $white;
      }
    }
  }
}

.shortcut {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  margin-right: 12px;
  margin-left: 8px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 1px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  a:not(:last-child){
    .shortcut-icon {
      margin-right: 20px;
    }
  }
  &-icon {
    /*width: 32px;*/
    /*height: 32px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    opacity: 0.8;
    font-size: 22px;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    i {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
.regions {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  &-icon {
    color: rgba(255, 255, 255, 0.6);
  }
  &-icon-down {
    color: rgba(255, 255, 255, 0.6);
    margin-left: 4px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.selected {
    background-color: rgba(0, 0, 0, 0.1);
    color: $primary;
    i {
      color: $primary;
    }
  }
  .yv-dropdown-link {
    //color: $white;
    padding: 0 16px;
    height: $header-height;
    cursor: pointer;
    display: flex;
    align-items: center;
    > i {
      font-size: 16px;
      &.arrow-icon-down {
        font-size: 12px;
      }
    }
  }
  .back-button {
    background-color: rgba(0, 0, 0, 0.1);
    color: $white;
    padding: 0 16px;
    display: inline-block;
    height: $header-height;
    cursor: pointer;
    border: none;
  }
}
  .header-dropdown-menu {
    min-width: 100px;
    .yv-dropdown-menu{
      margin: 8px 0;
    }
    .yv-dropdown-menu__item {
      line-height: 36px;
      .yv-icon--item {
        color: $text-placeholder;
      }
      &:hover, &.active {
        color: $primary;
        .yv-icon--item {
          color: $primary;
        }
      }

      &:hover {
        background-color: transparent;
      }
      &.active {
        background-color: #e7f6f2;
      }
    }
  }
  .download-dropdown-menu {
    &.yv-dropdown-menu{
      margin: 8px 0;
      margin-top: 12px;
    }
    .download-desc {
      padding-left: 12px;
      font-size: 14px;
      color: $text-secondary;
    }
  }
</style>
