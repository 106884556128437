import Cookies from "js-cookie";

const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : false,
    withoutAnimation: false,
  },
  activeTab: Cookies.get('__ai_current_activeTab'),
  menuList: []
};

const mutations = {
  setActiveTab (state, activeTab) {
    state.activeTab = activeTab
  },
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  SETMENU: (state, list) => {
    state.menuList = list
  }
};

const actions = {

  setActiveTab ({ commit }, activeTab) {
    //Cookies.set('__ai_current_activeTab', activeTab)
    commit('setActiveTab', activeTab)
  },
  toggleSideBar({commit}) {
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar({commit}, {withoutAnimation}) {
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  setMenu({commit}, {list}) {
    commit("SETMENU", list);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
