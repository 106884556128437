<template>
  <div class="page-detail-wrap">
    <div class="page-detail-header">
      <router-link :to="backPath" class="page-detail__back-btn">
        <yv-icon class="yv-icon-arrow-left"></yv-icon>
      </router-link>
      <yv-divider direction="vertical"></yv-divider>
      <div class="page-detail-title">
        <slot name="pageDetailTitle"></slot>
      </div>
      <div class="page-detail-tips" v-if="$slots.pageDetailTips">
        <slot name="pageDetailTips"></slot>
      </div>
    </div>
    <div class="page-detail-main__scrollbar " v-if="scrollbar">
      <yv-scrollbar>
        <slot></slot>
        <div class="pb16"></div>
      </yv-scrollbar>
    </div>
    <div class="page-detail-main" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageDetail",
  props: {
    backPath: {
      type: [Object, String],
      default: "/",
    },
    scrollbar: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@a/style/variables.scss";
.page-detail-wrap {
  .page-detail-header {
    position: relative;
    display: flex;
    align-items: center;
    height: $header-height;
    padding: 0 16px;
    background-color: #ffffff;
    // box-shadow: $white-box-shadow;
    border-bottom:$border-base;
    // margin-bottom: 1px;
    .page-detail__back-btn {
      cursor: pointer;
      color: $text-primary;
      vertical-align: middle;
      padding: 4px 8px;
      border-radius: $border-radius-base;
      transition: all 0.2s;
      border: 1px solid transparent;
      overflow: hidden;
      font-weight: bold;
      &:hover {
        text-decoration: none;
        background-color: $primary-color-1;
        color: $primary;
      }
      &:active {
        background-color: mix($primary, $primary-color-1, 5%);
      }
    }
    .page-detail-title {
      display: flex;
      align-items: center;
      margin-left: 12px;
      .title-logo {
        width: 30px;
        background-color: $bg-base;
        border-radius: 6px;
        margin-right: 8px;
      }
      .title-icon {
        color: $primary;
        vertical-align: middle;
        + .title-style {
          padding-left: 12px;
        }
      }
      .title-style {
        display: flex;
        align-items: center;
        .text {
          font-weight: 700;
          color: $text-primary;
          font-size: 16px;
        }
        .sn {
          font-size: 12px;
          color: $text-secondary;
          margin-left: 8px;
        }
      }

      ::v-deep .yv-breadcrumb {
        display: flex;
        align-items: center;
        .yv-breadcrumb__item {
          display: flex;
          align-items: center;
        }
        .yv-breadcrumb__inner {
          display: flex;
          align-items: center;

          .bread-logo {
            display: inline-block;
            img {
              height: 36px;
              width: auto;
            }
          }
          .bread-text {
            display: inline-block;
            margin-left: 4px;
          }
        }
      }
    }

    .page-detail-tips {
      font-size: 14px;
      position: absolute;
      right: 16px;
    }
  }
  .page-detail-main {
    height: calc(100vh - #{$header-height * 2} - 1px);
    overflow: hidden;
  }
  .page-detail-main__scrollbar {
    overflow: hidden;
    ::v-deep {
      .yv-scrollbar__wrap {
        box-sizing: border-box;
        padding: 15px 16px 0;
        height: calc(100vh - #{$header-height*2} - 1px);
        overflow-x: hidden;
      }
    }
  }
}

</style>
