import Cookies from "js-cookie";
const state = {
  user: {},
  regions: [],
};

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setRegion (state, region) {
    state.regions = region
  },
  clearUser (state) {
    state.user = {}
  }
};

const actions = {
  setUser ({ commit }, user) {
    //Cookies.set('__hpc_ai_user', user && JSON.stringify(user.hpcSession))
    commit('setUser', user)
  },
  setRegion ({ commit }, region) {
    // Cookies.set('__hpc_ai_regions', JSON.stringify(region))
    commit('setRegion', region)
  },
  logout ({ commit }) {
    Cookies.remove('__hpc_ai_user')
    Cookies.remove('__hpc_ai_regions')
    localStorage.clear()
    commit('clearUser')
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
