const protocol = location.protocol
let globalDomain = location.host.substring(location.host.indexOf('.') + 1)
let $config

if (process.env.NODE_ENV === 'development') {
  $config = {
    version: '1.0.0',
    author: globalDomain,
    description: '',

    passport: {
      api_root: protocol + '//passport.yovole.com/controller'
    },

    bp: {
      ui_root: protocol + '//bp.yovole.com',
    },

    ubp: {
      api_root: protocol + '//ubp.yovole.com/controller'
    },

    cloud: {
      ui_root: protocol + '//bp.yovole.com',
      help_ui_root: protocol + '//bp.yovole.com/help',
      help_root: protocol + '//bp.yovole.com'
    },

    cp: {
      ui_root: protocol + '//console.yovole.com',
    },

    hpc: {
      ui_root: protocol + '//hpc.yovole.com',
      ftp_root: 'http://hpc.yovole.com/hpc-ftp/',
      ssh_shell_root: 'http://hpc.yovole.com/ssh/'
    },

    ai: {
      ui_root: protocol + '//dev.ai.yovole.com:8080',
      api_root: protocol + '//ai.yovole.com/ai/',
      ftp_root: 'http://ai.yovole.com/ai-ftp/',
      help_root: protocol + '//cloud.yovole.com/ai-doc/'
    },

    oss: {
      ui_root: protocol + '//os2.yovole.com'
    },

    cdn: {
      ui_root: protocol + '//cdn.yovole.com'
    },

    cds: {
      ui_root: protocol + '//cds.yovole.com'
    },

    cls: {
      ui_root: protocol + '//cls.yovole.com'
    },

    csk: {
      ui_root: protocol + '//csk.yovole.com'
    },

    crs: {
      ui_root: protocol + '//crs.yovole.com'
    },
    exp: {
      ui_root: protocol + '//task.yovole.com'
    },
    cfs: {
      ui_root: protocol + '//cfs.yovole.com'
    }
  }
} else if (location.host.indexOf('yinhanyun.com') >= 0) {
  globalDomain = 'yinhanyun.com'
  $config = {
    version: '1.0.0',
    author: globalDomain,
    description: '',

    passport: {
      api_root: protocol + '//passport.' + globalDomain + '/passport/controller'
    },

    bp: {
      ui_root: protocol + '//bp.' + globalDomain + '/bp/',
      help_ui_root:  protocol + '//bp.' + globalDomain + '/help',
    },

    ubp: {
      api_root: protocol + '//ubp.yovole.com/controller'
    },

    cloud: {
      ui_root: protocol + '//bp.' + globalDomain + '/bp/',
      help_ui_root: protocol + '//bp.' + globalDomain + '/help',
      help_root: protocol + '//bp.' + globalDomain
    },

    cp: {
      ui_root: protocol + '//console.' + globalDomain + '/console/',
    },

    hpc: {
      ui_root: protocol + '//hpc.' + globalDomain + '/hpc/',
    },

    ai: {
      ui_root: protocol + '//ai.' + globalDomain + '/ai/',
      api_root: protocol + '//ai.' + globalDomain + '/ai/',
      ftp_root: protocol + '//ai.' + globalDomain + '/ai-ftp/',
      help_root: protocol + '//ai.' + globalDomain + '/ai-doc/'
    },

    oss: {
      ui_root: protocol + '//os2.' + globalDomain + '/os2/'
    },

    cdn: {
      ui_root: protocol + '//cdn.' + globalDomain + '/cdn/'
    },

    cds: {
      ui_root: protocol + '//cds.' + globalDomain + '/cds/'
    },

    crs: {
      ui_root: protocol + '//crs.' + globalDomain + '/crs/'
    },

    cls: {
      ui_root: protocol + '//cls.' + globalDomain + '/cls/'
    },

    csk: {
      ui_root: protocol + '//csk.' + globalDomain + '/csk/'
    },
    exp: {
      ui_root: protocol + '//task.' + globalDomain + '/task/'
    },
    cfs: {
      ui_root: protocol + '//cfs.' + globalDomain + '/cfs/'
    }
  }
} else if (location.host.indexOf('yovole.com') > 0) {
  if (location.host.indexOf('.cloud.yovole.com') > 0) {
    globalDomain = location.host
    $config = {
      version: '1.0.0',
      author: globalDomain,
      description: '',

      passport: {
        api_root: protocol + '//' + globalDomain + '/passport/controller'
      },

      bp: {
        ui_root: protocol + '//' + globalDomain + '/bp/',
      },

      ubp: {
        api_root: protocol + '//ubp.yovole.com/controller'
      },

      cloud: {
        ui_root: protocol + '//' + globalDomain + '/bp/',
        help_ui_root: protocol + '//bp.yovole.com/help',
        help_root: protocol + '//' + globalDomain
      },

      cp: {
        ui_root: protocol + '//' + globalDomain + '/console/',
      },

      hpc: {
        ui_root: protocol + '//' + globalDomain + '/hpc/',
      },

      ai: {
        ui_root: protocol + '//' + globalDomain + '/ai/',
        api_root: protocol + '//' + globalDomain + '/ai/',
        ftp_root: protocol + '//' + globalDomain + '/ai-ftp/',
        help_root: protocol + '//cloud.yovole.com/ai-doc/'
      },

      oss: {
        ui_root: protocol + '//' + globalDomain + '/os2/'
      },

      cdn: {
        ui_root: protocol + '//' + globalDomain + '/cdn/'
      },

      cds: {
        ui_root: protocol + '//' + globalDomain + '/cds/'
      },

      crs: {
        ui_root: protocol + '//' + globalDomain + '/crs/'
      },

      cls: {
        ui_root: protocol + '//' + globalDomain + '/cls/'
      },

      csk: {
        ui_root: protocol + '//' + globalDomain + '/csk/'
      },
      exp: {
        ui_root: protocol + '//' + globalDomain + '/task/'
      },
      cfs: {
        ui_root: protocol + '//' + globalDomain + '/cfs/'
      }
    }
  } else {
    $config = {
      version: '1.0.0',
      author: 'yovole.com',
      description: '',
      passport: {
        api_root: protocol + '//passport.yovole.com/controller'
      },
      bp: {
        ui_root: protocol + '//bp.yovole.com',
      },
      ubp: {
        api_root: protocol + '//ubp.yovole.com/controller'
      },
      cloud: {
        ui_root: protocol + '//bp.yovole.com',
        help_ui_root: protocol + '//bp.yovole.com/help',
        help_root: protocol + '//bp.yovole.com'
      },
      cp: {
        ui_root: protocol + '//console.yovole.com',
      },
      hpc: {
        ui_root: protocol + '//hpc.yovole.com',
        ftp_root: protocol + '//hpc.yovole.com/hpc-ftp/',
        ssh_shell_root: protocol + '//hpc.yovole.com/ssh/'
      },
      ai: {
        ui_root: protocol + '//ai.yovole.com',
        api_root: protocol + '//ai.yovole.com/ai/',
        ftp_root: protocol + '//ai.yovole.com/ai-ftp/',
        help_root: protocol + '//cloud.yovole.com/ai-doc/',
      },
      oss: {
        ui_root: protocol + '//os2.yovole.com'
      },
      cdn: {
        ui_root: protocol + '//cdn.yovole.com'
      },
      cds: {
        ui_root: protocol + '//cds.yovole.com'
      },
      crs: {
        ui_root: protocol + '//crs.yovole.com'
      },
      cls: {
        ui_root: protocol + '//cls.yovole.com'
      },
      csk: {
        ui_root: protocol + '//csk.yovole.com'
      },
      exp: {
        ui_root: protocol + '//task.yovole.com'
      },
      cfs: {
        ui_root: protocol + '//cfs.yovole.com'
      }
    }
  }
} else {
  $config = {
    version: '1.0.0',
    author: globalDomain,
    description: '',
    passport: {
      api_root: protocol + '//cloud.' + globalDomain + '/passport/controller'
    },
    bp: {
      ui_root: protocol + '//cloud.' + globalDomain + '/bp/',
    },
    ubp: {
      api_root: protocol + '//ubp.yovole.com/controller'
    },
    cloud: {
      ui_root: protocol + '//cloud.' + globalDomain + '/bp/',
      help_ui_root: protocol + '//cloud.' + globalDomain + '/help',
      help_root: protocol + '//cloud.' + globalDomain
    },
    cp: {
      ui_root: protocol + '//cloud.' + globalDomain + '/console/',
    },
    hpc: {
      ui_root: protocol + '//cloud.' + globalDomain + '/hpc/',
    },
    ai: {
      ui_root: protocol + '//cloud.' + globalDomain + '/ai/',
      api_root: protocol + '//cloud.' + globalDomain + '/ai/',
      ftp_root: protocol + '//cloud.' + globalDomain + '/ai-ftp/',
      help_root: protocol + '//cloud.yovole.com/ai-doc/',
    },
    oss: {
      ui_root: protocol + '//cloud.' + globalDomain + '/os2/'
    },
    cdn: {
      ui_root: protocol + '//cloud.' + globalDomain + '/cdn/'
    },
    cds: {
      ui_root: protocol + '//cloud.' + globalDomain + '/cds/'
    },
    crs: {
      ui_root: protocol + '//cloud.' + globalDomain + '/crs/'
    },
    cls: {
      ui_root: protocol + '//cloud.' + globalDomain + '/cls/'
    },
    csk: {
      ui_root: protocol + '//cloud.' + globalDomain + '/csk/'
    },
    exp: {
      ui_root: protocol + '//cloud.' + globalDomain + '/task/'
    },
    cfs: {
      ui_root: protocol + '//cloud.' + globalDomain + '/cfs/'
    }
  }
}

export default $config
