import axios from './index'

function getBizMenu () {
  return axios.post('/common/user-business/list').then(data => data.object)
}

function getUserInfo () {
  return axios.get('/common/user/initInfo').then(data => data.object)
}

function getLogout () {
  return axios.post('/common/logout').then(data => data.object)
}
function getAccountQos () {
  return axios.get('/common/account/qos').then(data => data.object)
}

function getModelGroupList () {
    return axios.get('/common/model-groups/list').then(data =>data.objects)
}

function getModelList (type) {
    return axios.get('/common/models/list?type='+(type||'')).then(data =>data.objects)
}

function getResourceStatus (params) {
    return axios.post('/common/resource/status', params).then(data =>data.objects)
}

export default {
  getBizMenu,
  getUserInfo,
  getLogout,
  getAccountQos,
  getModelGroupList,
  getModelList,
  getResourceStatus
}
