export default [
    {
        path: "/image/cpu",
        name: "CpuImage",
        component: () => import(/* webpackChunkName: "image" */ "@/views/image/cpu/index"),
        meta: {title: "CPU镜像管理", activeMenu: "/image/cpu"},
    },
    {
        path: "/image/cpu/detail",
        name: "CpuDetail",
        component: () => import(/* webpackChunkName: "image" */"@/views/image/cpu/detail"),
        meta: {title: "CPU镜像管理", activeMenu: "/image/cpu"},
    },
    {
        path: "/image/gpu",
        name: "GpuImage",
        component: () => import(/* webpackChunkName: "image" */"@/views/image/gpu/index"),
        meta: {title: "GPU镜像管理", activeMenu: "/image/gpu"},
    },
    {
        path: "/image/gpu/detail",
        name: "GpuDetail",
        component: () => import(/* webpackChunkName: "image" */"@/views/image/gpu/detail"),
        meta: {title: "GPU镜像管理", activeMenu: "/image/gpu"},
    },
    {
        path: "/image/tag",
        name: "TagManage",
        component: () => import(/* webpackChunkName: "image" */"@/views/image/tag"),
        meta: {title: "标签管理", activeMenu: "/image"},
    },
]
